import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { formatDateTime } from '../../lib/utils';
import { SortingGroup, Pagination, DropDown } from '@arcade/component-library';
import type { Campaign, CampaignsDataHeaders } from '../../pages/Dashboaard/dashboard';
import { sortingOptions } from './sorting';

import {
  TableWrapper,
  TableRow,
  FilterWrapper,
  RowsPerPage,
  PaginationWrapper,
  PageCount,
} from './table.styles';

const isRunning = (start:string, end?:string):boolean => {
  const startDate = new Date(start);
  const endDate = end ? new Date(end) : null;
  const today = new Date();
  if (startDate.getTime() <= today.getTime() && ( !endDate || today.getTime() <= endDate.getTime())) return true;
  return false;
};

const calculateLimits = (campaignDataHeaders: CampaignsDataHeaders | null) => {
  if (!campaignDataHeaders) { return [0, 0, 0]; }
  const upToAndIncludingThisPage = campaignDataHeaders.currentPage * campaignDataHeaders.itemsPerPage;
  const startingNumber = upToAndIncludingThisPage - campaignDataHeaders.itemsPerPage + 1;
  const endNumber = upToAndIncludingThisPage > campaignDataHeaders.totalItems ? campaignDataHeaders.totalItems : upToAndIncludingThisPage;
  const totalItems = campaignDataHeaders.totalItems;
  return [startingNumber, endNumber, totalItems];
};

const Table = ({
  campaigns,
  updateCampaigns,
  page,
  setPage,
  itemsPerPage,
  setItemsPerPage,
  campaignDataHeaders,
} : {
  campaigns: Campaign[],
  updateCampaigns: (_sort?: string) => Promise<void>,
  page: number,
  setPage: (_p: number) => void,
  itemsPerPage: number,
  setItemsPerPage: (_p: number) => void,
  campaignDataHeaders: CampaignsDataHeaders | null,
}): React.ReactElement => {
  const [sorting, setSorting] = useState('default');

  const handlePaginationClick = (pageSelected: number | string) => {
    setPage(Number(pageSelected));
  };

  const handleRowsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setItemsPerPage(Number(e.target.value));
  };

  const onSortingChange = (id: string) => {
    setSorting(id);
  };

  const [pageCountStart, pageCountEnd, pageCountTotal] = useMemo(() => calculateLimits(campaignDataHeaders), [campaignDataHeaders]);

  useEffect(() => {
    updateCampaigns(sorting);
  }, [sorting]);

  return (
    <>
      <FilterWrapper>
        <div>Search and Filters</div>
        <SortingGroup
          items={sortingOptions}
          selected={sorting}
          onChange={onSortingChange}
        />
      </FilterWrapper>
      <TableWrapper>
        <thead>
          <tr>
            <th>name</th>
            <th>Created by</th>
            <th>Last Edited By</th>
            <th>type</th>
            <th>status</th>
            <th>start Date</th>
            <th>end Date</th>
            <th>actions</th>
            <th/>
          </tr>
        </thead>
        <tbody>
          {campaigns.map(col => {
            const campaignLive = isRunning(col.startDate as string, col.endDate);
            const status = col.status === 'published' && campaignLive ? 'running' : col.status;
            return (
              <TableRow key={col.id} status={status}>
                <td>{col.name}</td>
                <td>{col.createdBy}</td>
                <td>{col.editedBy}</td>
                <td>{col.type}</td>
                <td>{status}</td>
                <td>{col.startDate ? formatDateTime(col.startDate) : 'n/a'}</td>
                <td>{col.endDate ? formatDateTime(col.endDate) : 'n/a'}</td>
                <td>{col?.actions?.length || 0}</td>
                <td><Link to={`/campaign/${col.id}`}>View</Link></td>
              </TableRow>
            );
          })}
        </tbody>
      </TableWrapper>
      <FilterWrapper>
        <RowsPerPage>
          <p>Rows per page: </p>
          <DropDown
            id='campaign-table'
            testid='campaign-table'
            className='table-rowspp'
            options={[
              // Added 5 to test pagination with few items
              {
                value: '5',
                label: '5'
              },
              {
                value: '25',
                label: '25'
              },
              {
                value: '50',
                label: '50'
              },
              {
                value: '100',
                label: '100'
              }
            ]}
            selected={itemsPerPage.toString()}
            onChange={handleRowsPerPageChange}
          />
        </RowsPerPage>
        {campaignDataHeaders && !!campaignDataHeaders.lastPage && (
          <PaginationWrapper>
            <Pagination
              pages={campaignDataHeaders.lastPage}
              pageSelected={page}
              onPageClick={handlePaginationClick}
              useShowAll={false}
            />
          </PaginationWrapper>
        )}
        {campaignDataHeaders && !!campaignDataHeaders.itemsPerPage && !!campaignDataHeaders.currentPage && !!campaignDataHeaders.totalItems && (
          <PageCount>{pageCountStart}-{pageCountEnd} of {pageCountTotal}</PageCount>
        )}
      </FilterWrapper>
    </>
  );
};

export default Table;
