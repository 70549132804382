import React from 'react';
import { Controller, FieldArrayWithId, type Control } from 'react-hook-form';

import BaseAction from './baseAction';
import { FormField, TextBox } from '@arcade/component-library';

import ActionField from './actionField';

import { type ActionsFormType } from '../ActionsForm/actionForm';

const ProductTitleActionField = ({ control, index, field, errors, onRemove } : {
  control: Control<ActionsFormType>,
  index: number,
  field: FieldArrayWithId,
  errors: any,
  onRemove: (_i:number) => void
}):React.ReactElement => {

  return (
    <ActionField onRemove={() => onRemove(index)} actionName='Modify Product Title'>
      <BaseAction {...{ control, index, field, errors }} />
      <Controller
        name={`actions.${index}.content`}
        control={control}
        rules={{
          required: 'Please enter some content'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-content`}
            label='Content'
            errorLabel={errors?.actions?.[index]?.content?.message || ''}
            required
          >
            <TextBox {...field} id={`action-${index}-content`} state={errors?.actions?.[index]?.content ? 'error' : 'normal'} />
          </FormField>
        )}
      />
    </ActionField>
  );
};

export default ProductTitleActionField;
