import React, { useState } from 'react';
import _getOr from 'lodash/fp/getOr';
import { useForm, FormProvider, SubmitHandler, Controller } from 'react-hook-form';
import { FormField, Checkbox, TextBox, DropDown } from '@arcade/component-library';

// import ActionFields, { type ActionFiledsForm } from '../ActionFields/actionFields';

import PromoField from '../PromoField/promoField';

export interface CampaignFormType {
  name: string
  description: string
  sites?: { [key:string]: boolean }
  channels?: { [key:string]: boolean }
  type: string
  startDate: string
  endDate: string
  promoCodes: string[]
  // actions: ActionFiledsForm
}

export type Site = {
  code?: string
  name?: string
}

import {
  InlineFields,
  TextArea
} from './campaignForm.styles';

const CampaignForm = ({ formId, sites, channels, campaign, onSubmit } : { formId: string, sites:Site[], channels: Site[], campaign?:CampaignFormType, onSubmit?: (_data:CampaignFormType) => void}):React.ReactElement => {
  const [allSites, setAllSites] = useState<boolean>(false);
  const [allChannels, setAllChannels] = useState<boolean>(false);

  const methods = useForm<CampaignFormType>();
  const { handleSubmit, control, formState: { errors } } = methods;

  const submitForm:SubmitHandler<CampaignFormType> = data => {
    if(onSubmit) onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form
        id={formId}
        onSubmit={handleSubmit(submitForm)}
      >
        <Controller
          name='name'
          control={control}
          defaultValue={_getOr('', 'name', campaign) ?? ''}
          rules={{
            required: 'Please add a name to the campaign'
          }}
          render={({ field }) => (
            <FormField
              id='campaign-name'
              label='Campaign Name'
              errorLabel={errors.name?.message}
              required
            >
              <TextBox {...field} type='text' id='campaign-name' state={errors.name ? 'error' : 'normal'} testid='campaign-name' />
            </FormField>
          )}
        />
        <Controller
          name='description'
          control={control}
          defaultValue={campaign?.description ?? ''}
          render={({ field }) => (
            <FormField
              id='campaign-desc'
              label='Campaign Description'
            >
              <TextArea {...field} id='campaign-desc' rows={5}/>
            </FormField>
          )}
        />
        <Controller
          name='type'
          control={control}
          defaultValue={_getOr('', 'type', campaign) ?? ''}
          rules={{
            required: 'Please select a campaign type'
          }}
          render={({ field }) => (
            <FormField
              id='campaign-type'
              label='Campaign type'
              errorLabel={errors.type?.message}
              required
            >
              <DropDown
                {...field}
                id='campaign-type'
                testid='campaign-type'
                selected={_getOr('', 'type', campaign) ?? ''}
                error={!!errors.type}
                options={[
                  {
                    value: 'site_wide',
                    label: 'Site-wide'
                  },
                  {
                    value: 'targeted',
                    label: 'Targeted'
                  }
                ]}
                placeholder='please select' />
            </FormField>
          )}
        />
        <InlineFields>
          <FormField
            label='Select targeting sites'
          >
            <Controller
              name='sites.all'
              control={control}
              defaultValue={_getOr(false, 'all', campaign?.sites)}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  label='Select all sites'
                  checked={_getOr(false, 'all', campaign?.sites)}
                  onChange={checked => {field.onChange(checked); setAllSites(checked);}}
                />
              )}
            />
            <p>-- or --</p>
            {sites.map(site => (
              <Controller
                key={site.code}
                name={`sites.${site.code}`}
                defaultValue={_getOr(false, `${site.code}`, campaign?.sites)}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={_getOr(false, `${site.code}`, campaign?.sites)}
                    label={site.name}
                    disabled={allSites}
                  />
                )}
              />
            ))}
          </FormField>
          <FormField
            label='Select targeting territories'
          >
            <Controller
              name='channels.all'
              control={control}
              defaultValue={_getOr(false, 'all', campaign?.channels)}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={_getOr(false, 'all', campaign?.channels)}
                  label='Select all Channels'
                  onChange={checked => {field.onChange(checked); setAllChannels(checked); }}
                />
              )}
            />
            <p>-- or --</p>
            {channels.map(channel => (
              <Controller
                key={channel.code}
                name={`channels.${channel.code}`}
                defaultValue={_getOr(false, `${channel.code}`, campaign?.channels)}
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    checked={_getOr(false, `${channel.code}`, campaign?.channels)}
                    label={channel.name}
                    disabled={allChannels}
                  />
                )}
              />
            ))}
          </FormField>
        </InlineFields>
        <InlineFields>
          <Controller
            name='startDate'
            control={control}
            defaultValue={_getOr('', 'startDate', campaign) ?? ''}
            rules={{
              required: 'Please enter a start date/time',
            }}
            render={({ field }) => (
              <FormField
                id='campaign-startDate'
                label='Start Date'
                extraInfo='Dates and times are local'
                errorLabel={errors?.startDate?.message}
                required
              >
                <TextBox
                  {...field}
                  id='campaign-startDate'
                  type='datetime-local'
                  state={errors.startDate ? 'error' : 'normal'}
                />
              </FormField>
            )}
          />
          <Controller
            name='endDate'
            control={control}
            defaultValue={_getOr('', 'endDate', campaign) ?? ''}
            render={({ field }) => (
              <FormField
                id='campaign-endDate'
                label='End Date'
                extraInfo='Dates and times are local'
              >
                <TextBox {...field} id='campaign-endDate' type='datetime-local' />
              </FormField>
            )}
          />
        </InlineFields>
        <Controller
          name='promoCodes'
          control={control}
          render={() => (
            <FormField
              id='campaign-promo'
              label='Promcode'
            >
              <PromoField handleSelection={val => console.log(val)} selectedPromos={[{ id: 'BGT56', text: 'BGT56' }]} />
            </FormField>
          )}
        />
        {/* <ActionFields {...{ control, errors }} initialData={campaign?.actions ?? []} /> */}
      </form>
    </FormProvider>
  );
};

export default CampaignForm;
