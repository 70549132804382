import { css, styled } from 'styled-components';

export const InlineFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media (min-width: ${props => props.theme.breakpoints.mobileL}) {
    flex-direction: row;
  }

  button {
    margin-bottom: 0;
  }
`;

export const ActionWrapper = styled.div`
  border: 1px solid ${props => props.theme.colors.lightGrey};
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
`;

export const ActionBtn = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    margin-right: 5px;
  }
`;

export const FormActionsWrapper = styled.div`
  hr {
    margin: 15px 0;
    border: 1px solid ${props => props.theme.colors.lightGrey};
  }

  button svg {
    margin-right: 10px;
  }
`;

export const QueryDescription = styled.div`
  border: 1px solid ${props => props.theme.colors.darkGrey};
  color: ${props => props.theme.colors.darkGrey};
  padding: 10px;
  font-size: 12px;
  line-height: 14px;
  background-color: ${props => props.theme.colors.warningLight};
  margin-top: 5px;
  border-radius: 3px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HalfContent = styled.div`
  width: 50%;
  overflow: scroll;
  height: 100%;
`;

export const FieldsContainer = styled.div`
  margin: 20px 20px 0 0;
  border-top: 1px solid ${props => props.theme.colors.lightGrey};
`;

export const FieldTab = styled('button').withConfig({
  shouldForwardProp: prop =>
    !['selected', 'errors'].includes(prop)
}
)<{selected: boolean, errors: boolean}>`
  padding: 20px;
  background: none;
  border: 1px solid ${props => props.theme.colors.grey};
  border-radius: 5px;
  margin-top: 15px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${props => props.selected && css`
    border-color: ${props => props.theme.layout.primary};
  `}

  ${props => props.errors && css`
    border-color: ${props => props.theme.colors.error};
  `}

  span {
    line-height: ${props => props.theme.lineHeights.lh1};
  }

  &:hover {
    border-color: ${props => props.theme.layout.primary};
  }
`;

export const ActionContentWrapper = styled('div').withConfig({
  shouldForwardProp: prop =>
    !['selected'].includes(prop)
})<{selected: boolean}>`
  display: none;

  ${props => props.selected && css`display: block;`}
`;

export const ErrorWrapper = styled.i`
  color: ${props => props.theme.colors.error};
`;
