import React from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';

import ActionFields, { type ActionFiledsForm } from '../ActionFields/actionFields';

export interface ActionsFormType {
  actions: ActionFiledsForm
}

export type ActionsFormProps = {
  formId: string
  actionsData?: ActionFiledsForm
  onSubmit?: (_data:ActionsFormType) => void
}

const ActionsForm = ({ formId, actionsData, onSubmit }:ActionsFormProps):React.ReactElement => {

  const methods = useForm<ActionsFormType>();
  const { handleSubmit, control, formState: { errors } } = methods;

  const submitForm:SubmitHandler<ActionsFormType> = data => {
    if(onSubmit) onSubmit(data);
  };

  return (
    <FormProvider {...methods}>
      <form
        id={formId}
        onSubmit={handleSubmit(submitForm)}
      >
        <ActionFields {...{ control, errors }} initialData={actionsData ?? []} />
      </form>
    </FormProvider>
  );
};

export default ActionsForm;
