import React, { useRef, useEffect, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import { FormField, Button, DropDown, Title, Icon } from '@arcade/component-library';

import { type ActionsFormType } from '../ActionsForm/actionForm';

import BannerActionField from './bannerActionField';
import ProductDescriptionActionField from './productDescriptionActionField';
import ProductTitleActionField from './productTitleActionField';
import ProductSubtitleActionField from './productSubtitleActionField';

const availableFields = [
  {
    value: 'banner',
    label: 'Modify Banner',
    component: BannerActionField,
    formValue: 'Banner'
  },
  {
    value: 'productDescription',
    label: 'Modify Product Description',
    component: ProductDescriptionActionField,
    formValue: 'ModifyProductDescription'
  },
  {
    value: 'productTitle',
    label: 'Modify Product Title',
    component: ProductTitleActionField,
    formValue: 'ModifyProductTitle'
  },
  {
    value: 'productSubtitle',
    label: 'Modify Product Subtitle',
    component: ProductSubtitleActionField,
    formValue: 'ModifyProductSubtitle'
  }
];

export type ActionFiledsForm = {
  id?: string
  mode?: string
  targetingString?:string
  type?: string
  placement?: string
  content?: string
  description?:string
  desktopAsset?: { id: string }
  tabletAsset?: { id: string }
  mobileAsset?: { id: string }
  link?: string
  alternativeText?: string
}[]

import {
  InlineFields,
  FormActionsWrapper,
  ContentWrapper,
  HalfContent,
  FieldsContainer,
  FieldTab,
  ActionContentWrapper,
  ErrorWrapper
} from './actionFields.styles';

const ActionFields = ({ control, errors, initialData } : {
  control: Control<ActionsFormType>,
  errors: any
  initialData?: ActionFiledsForm
}):React.ReactElement => {
  const [selectedActionId, setSelectedActionId] = useState<number>();
  const addActiondrop = useRef<HTMLSelectElement>(null);
  const { fields, append, remove, replace } = useFieldArray<ActionsFormType>({
    name: 'actions',
    control
  });

  useEffect(() => {
    if(initialData && initialData.length > 0) {
      replace(initialData);
      if (!selectedActionId) setSelectedActionId(0);
    }
  }, [initialData]);

  const handleAddAction = ():void => {
    const dropValue = addActiondrop.current?.value;
    if (dropValue) {
      const formType = availableFields.filter(f => f.value === dropValue)[0].formValue;
      append({
        mode: '',
        targetingString: '',
        type: formType,
      });
      setSelectedActionId(fields.length);
    }
  };

  const handleRemove = (index:number):void => {
    remove(index);
  };

  const handleActionClick = (e:React.MouseEvent<HTMLButtonElement>, index:number) => {
    e.preventDefault();
    setSelectedActionId(index);
  };

  return (
    <FormActionsWrapper>
      <Title tag='h4' text='Add New Actions'/>
      <ContentWrapper>
        <HalfContent>
          <InlineFields>
            <FormField half>
              <DropDown
                options={availableFields}
                placeholder='Select action type'
                id='action_select'
                testid='action_select'
                ref={addActiondrop}
              />
            </FormField>
            <FormField half>
              <Button type="button" onClick={handleAddAction}><Icon glyph='add' />Add</Button>
            </FormField>
          </InlineFields>
          <FieldsContainer>
            {fields.map( (field, i )=> {
              const hasErrors = !!errors?.actions?.[i];
              return (
                <FieldTab onClick={e => handleActionClick(e, i)} key={field.id} selected={selectedActionId === i} errors={errors?.actions?.[i] || false}>
                  <span>{hasErrors && <ErrorWrapper><Icon glyph='error_warning'/></ErrorWrapper>}<b>{field.type} Action</b><br/>{field.description}</span>
                  <Icon glyph='arrow_right' />
                </FieldTab>
              );
            })}
          </FieldsContainer>
        </HalfContent>
        <HalfContent>
          <div>
            {fields.map((field, index) => {
              const Component = availableFields.filter(f => f.formValue === field.type)[0].component;
              return (
                <ActionContentWrapper key={field.id} selected={selectedActionId === index}>
                  <Component onRemove={handleRemove} {...{ control, index, field, errors }} />
                </ActionContentWrapper>
              );
            })}
          </div>
        </HalfContent>
      </ContentWrapper>
    </FormActionsWrapper>
  );
};

export default ActionFields;
