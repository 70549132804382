
import { components } from '../openapi';
import { Client } from '../../apiConfig';

export const CampaignsApi = (client: Client) => ({
  getCampaigns: ({ token, sorting, page, itemsPerPage } : { token: string, sorting?: string, page?: number, itemsPerPage?: number }) => {
    return client.GET('/api/ace/campaigns', {
      params: {
        query: {
          ...(sorting ? { [`order[${sorting.split(':')[0]}]`]: sorting.split(':')[1] } : {}),
          ...(page ? { page: page } : {}),
          ...(itemsPerPage ? { itemsPerPage: itemsPerPage } : {})
        }
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  addCampaigns: ({ campaign, token } : {
    campaign: components['schemas']['Campaign-ace_campaign.write']
    token: string
  }) => {
    return client.POST('/api/ace/campaigns', {
      body: campaign,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  getCampaignById: ({ campaignId, token }: { campaignId:string, token: string }) => {
    return client.GET('/api/ace/campaigns/{id}', {
      params: {
        path: { id: campaignId }
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  updateCampaignById: ({ campaignId, data, token }: {
    campaignId: string,
    data: components['schemas']['Campaign-ace_campaign.write']
    token: string
  }) => {
    return client.PATCH('/api/ace/campaigns/{id}', {
      params: {
        path: { id: campaignId }
      },
      body: data,
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  },
  deleteCampaignById: ({ campaignId, token }: { campaignId: string, token:string }) => {
    return client.DELETE('/api/ace/campaigns/{id}', {
      params: {
        path: { id: campaignId }
      },
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
});
