import React, { useState, useEffect, useContext } from 'react';
import PageWrapper from '../../components/PageWrapper/pageWrapper';
import Table from '../../components/Table/table';
import Loader from '../../components/Loader/loader';

import { userContext } from 'src/context/userContext';

import { components } from 'src/lib/api/openapi';
import { apiClient } from 'src/lib/apiConfig';

export type Campaign = components['schemas']['Campaign-ace_campaign.read'];
type Error = {
  type?: string
  title?: string
  detail?: string
}

export interface CampaignsDataHeaders {
  currentPage: number
  lastPage: number
  itemsPerPage: number
  totalItems: number
};

import {
  DashboardWrapper,
  PermissionError
} from './dashboard.styles';

const Dashboard = ():React.ReactElement => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [error, setError] = useState<Error>();
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [campaignDataHeaders, setCampaignDataHeaders] = useState<CampaignsDataHeaders | null>(null);

  const userDetails = useContext(userContext);

  const updateCampaigns = async (sorting?: string) => {
    const { data, error: apiError, response: apiResponse } = await apiClient.campaignsApi?.getCampaigns({
      token: userDetails.accessToken ?? '',
      ...(sorting && sorting !== 'default' ? { sorting } : {}),
      page,
      itemsPerPage
    });

    const responseHeaders = apiResponse.headers;
    setCampaignDataHeaders({
      currentPage: parseInt(responseHeaders.get('X-Current-Page') ?? '0', 10),
      lastPage: parseInt(responseHeaders.get('X-Last-Page') || '0', 10),
      itemsPerPage: parseInt(responseHeaders.get('X-Items-Per-Page') || '0', 10),
      totalItems: parseInt(responseHeaders.get('X-Total-Items') || '0', 10)
    });

    if (data) setCampaigns(data);
    if (apiError) {
      setError(apiError);
      console.error(apiError);
    };
  };

  useEffect(() => {
    updateCampaigns();
  }, []);

  return (
    <PageWrapper title='Dashboard'>
      <DashboardWrapper>
        {campaigns.length > 0 ? (
          <Table campaigns={campaigns} updateCampaigns={updateCampaigns} page={page} setPage={setPage} itemsPerPage={itemsPerPage} setItemsPerPage={setItemsPerPage} campaignDataHeaders={campaignDataHeaders} />
        ) : (
          error?.detail === 'Access Denied.' ? <PermissionError>You don&apos;t have the right permission to see this page. Please contact your manager to obtain the right permissions.</PermissionError> : <Loader />
        )}
      </DashboardWrapper>
    </PageWrapper>
  );
};

export default Dashboard;
