import React, { useContext } from 'react';
import { Controller, FieldArrayWithId, type Control, useFormContext} from 'react-hook-form';

import BaseAction from './baseAction';
import { FormField, DropDown, TextBox } from '@arcade/component-library';

import ImageUploadField from '../ImageUploadField/imageUploadField';
import { userContext } from 'src/context/userContext';

import ActionField from './actionField';

import { type ActionsFormType } from '../ActionsForm/actionForm';

const BannerActionField = ({ control, index, field, errors, onRemove } : {
  control: Control<ActionsFormType>,
  index: number,
  field: FieldArrayWithId,
  errors: any,
  onRemove: (_i:number) => void
}):React.ReactElement => {

  const { accessToken } = useContext(userContext);

  const { getValues } = useFormContext();

  return (
    <ActionField onRemove={() => onRemove(index)} actionName='Modify Banner'>
      <BaseAction {...{ control, index, field, errors }} />
      <Controller
        name={`actions.${index}.placement`}
        control={control}
        rules={{
          required: 'Please select a position'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-placement`}
            label='Position'
            errorLabel={errors?.actions?.[index]?.placement?.message || ''}
            required
          >
            <DropDown
              {...field}
              id={`action-${index}-placement`}
              testid={`action-${index}-placement`}
              placeholder='- Select -'
              options={[{ value: 'page-top', label: 'Page Top' }]}
              error={!!errors?.actions?.[index]?.placement}
              selected={field.value || ''}
            />
          </FormField>
        )}
      />
      <Controller
        name={`actions.${index}.link`}
        control={control}
        render={({ field }) => (
          <FormField
            id={`action-${index}-link`}
            label='Banner link'
          >
            <TextBox {...field} id={`action-${index}-link`} />
          </FormField>
        )}
      />
      <Controller
        name={`actions.${index}.alternativeText`}
        control={control}
        rules={{
          required: 'Please enter an alternative text for the image'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-alternativeText`}
            label='Image alternative text'
            errorLabel={errors?.actions?.[index]?.alternativeText?.message || ''}
            required
          >
            <TextBox {...field} id={`action-${index}-alternativeText`} state={errors?.actions?.[index]?.alternativeText ? 'error' : 'normal'} />
          </FormField>
        )}
      />
      <Controller
        name={`actions.${index}.desktopAsset.id`}
        control={control}
        rules={{
          required: 'Please enter a desktop image'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-desktopAsset`}
            label='Desktop Image'
            errorLabel={errors?.actions?.[index]?.desktopAsset?.id?.message || ''}
            required
          >
            <ImageUploadField {...field} asset={getValues(`actions.${index}.desktopAsset`)} description='Upload an image of size 2400px by 600px to be use as the banner desktop image' token={accessToken as string} />
          </FormField>
        )}
      />
      <Controller
        name={`actions.${index}.tabletAsset.id`}
        control={control}
        rules={{
          required: 'Please enter a tablet image'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-tabletAsset`}
            label='Tablet Image'
            errorLabel={errors?.actions?.[index]?.tabletAsset?.id.message || ''}
            required
          >
            <ImageUploadField {...field} asset={getValues(`actions.${index}.tabletAsset`)} description='Upload an image of size 2000px by 600px to be use as the banner tablet image' token={accessToken as string} />
          </FormField>
        )}
      />
      <Controller
        name={`actions.${index}.mobileAsset.id`}
        control={control}
        rules={{
          required: 'Please enter a mobile image'
        }}
        render={({ field }) => (
          <FormField
            id={`action-${index}-mobileAsset`}
            label='Mobile Image'
            errorLabel={errors?.actions?.[index]?.mobileAsset?.id.message || ''}
            required
          >
            <ImageUploadField {...field} asset={getValues(`actions.${index}.mobileAsset`)} description='Upload an image of size 1300px by 1540px to be use as the banner mobile image' token={accessToken as string} />
          </FormField>
        )}
      />
    </ActionField>
  );
};

export default BannerActionField;
