import React, { useState } from 'react';
import { Field, QueryBuilder, RuleGroupType, formatQuery } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';

import { FormField } from '@arcade/component-library';
import { Controller, type Control, useFormContext } from 'react-hook-form';

import { type ActionsFormType } from '../ActionsForm/actionForm';

export type BaseActionFields = {
  description:string
  targetingString:string
  type: string
}

import {
  QueryDescription
} from './actionFields.styles';

import {
  TextArea
} from '../CampaignForm/campaignForm.styles';

const baseQuery: RuleGroupType = {
  combinator: 'and',
  rules: []
};

const BaseAction = ({ control, index, errors } : {
  control: Control<ActionsFormType>,
  index: number,
  errors: any
 }):React.ReactElement => {
  const [query, setQuery] = useState<RuleGroupType>(baseQuery);

  const { setValue } = useFormContext();

  const fields: Field[] = [
    { name: 'productCodes', label: 'Product Codes' },
    { name: 'pageType', label: 'Page Type', valueEditorType: 'select', values: [{ name: 'homepage', value: 'homepage', label: 'Homepage' }, { name: 'pdp', value: 'pdp', label: 'PDP' }, { name: 'listing', value: 'listing', label: 'Listing' }] },
    { name: 'template', label: 'Template', valueEditorType: 'select', values: [{ name: 'default', value: 'default', label: 'Default' }, { name: 'single-issue', value: 'single-issue', label: 'Single Issue' }] },
    { name: 'blubirdPublicationCodes', label: 'Blubird Publication Codes' },
    { name: 'categories', label: 'Categories' },
    { name: 'flags', label: 'Flags' }
  ];

  const handleQueryChange = (q:RuleGroupType) => {
    setQuery(q);
    const stringValue = JSON.stringify(q);
    setValue(`actions.${index}.targetingString`, stringValue || '');
  };

  return(
    <div>
      <Controller
        name={`actions.${index}.type`}
        control={control}
        render={({ field }) => (
          <input {...field} type='hidden' />
        )}
      />
      <Controller
        name={`actions.${index}.description`}
        control={control}
        render={({ field }) => (
          <FormField
            id={`action-${index}-desc`}
            label='Description'
          >
            <TextArea {...field} id={`action-${index}-desc`} rows={3} />
          </FormField>
        )}
      />
      <Controller
        name={`actions.${index}.targetingString`}
        control={control}
        rules={{
          required: 'please select targeting'
        }}
        render={({ field }) => (
          <>
            <input {...field} type='hidden' />
            <FormField
              label='Targeting'
              errorLabel={errors?.actions?.[index]?.targetingString?.message || ''}
              required
            >
              <QueryBuilder fields={fields} defaultQuery={field?.value ? JSON.parse(field.value || '') : baseQuery} onQueryChange={handleQueryChange} />
              <QueryDescription>
                <p>{query && formatQuery(query, {
                  format: 'natural_language',
                  parseNumbers: true,
                  fields: fields
                })}</p>
              </QueryDescription>
            </FormField>
          </>
        )}
      />
    </div>
  );
};

export default BaseAction;
