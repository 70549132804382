import { css, styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  border: 1px solid ${props => props.theme.colors.darkGrey};
  padding: 10px;
  box-sizing: border-box;
  padding-bottom: 64px;
  position: relative;
  height: 100%;
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  background-color: ${props => props.theme.colors.lightestGrey};
  border-left: 1px solid ${props => props.theme.colors.lightGrey};
  border-right: 1px solid ${props => props.theme.colors.lightGrey};

  button {
    margin-right: 20px;
  }
`;

export const TabContent = styled('div').withConfig({
  shouldForwardProp: prop =>
    !['selected'].includes(prop)
})<{selected: boolean}>`
  display: none;
  overflow: auto;
  width: 100%;
  height: 100%;
  padding-bottom: 64px;

  ${props => props.selected && css`
    display: block;
  `}
`;

export const FormWrapper = styled.div`
  box-sizing: border-box;
  padding: 10px 20px 10px 10px;
  margin: 20px 0 10px;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.div`
  color: ${props => props.theme.colors.error};
  text-align: center;
`;

export const ActionsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
`;
